<template>
  <div class="my-counter">
    <button
      type="button"
      class="btn btn-light"
      @click="minus"
      :disabled="count < 2"
    >
      -
    </button>
    <input
      type="number"
      class="form-control inp"
      :value="count"
      @change="change"
    />
    <button type="button" class="btn btn-light" @click="add">+</button>
  </div>
</template>

<script>
export default {
  props: {
    count: {
      type: Number,
      default: 1,
    },
  },
  methods: {
    minus() {
      this.$emit('changeCount', this.count - 1)
    },
    add() {
      this.$emit('changeCount', this.count + 1)
    },
    change(e) {
      let count = +e.target.value
      if (count < 1) {
        count = 1
        e.target.value = 1
      }
      this.$emit('changeCount', count | 0)
    },
  },
}
</script>

<style lang="scss" scoped>
.my-counter {
  display: flex;
  .inp {
    width: 100px;
    text-align: center;
    margin: 0 10px;
  }
  .btn{
    width: 50px;
    border: none;
    background-color: #005ea6;
    color: #fff;
    border-radius: 5px;
  }
}
</style>
