<template>
  <!-- 底部 -->
  <div class="my-footer">
    <!-- 全选 -->
    <div class="custom-control custom-checkbox">
      <input type="checkbox" class="custom-control-input" id="footerCheck" v-model='isCheckAll' />
      <label class="custom-control-label" for="footerCheck">全选</label>
    </div>
    <!-- 合计 -->
    <div>
      <span>合计:</span>
      <span class="price">¥ {{totalPrice}}</span>
    </div>
    <!-- 按钮 -->
    <button :disabled='!count' type="button" class="footer-btn btn btn-primary" @click="$router.push('/settlement')">结算 ({{count}})</button>
  </div>
</template>

<script>
export default {
  props:{
    list:{
      type:Array,
    }
  },
  computed:{
    count(){
      return this.list.filter(item=>item.goods_state).reduce((prev,item)=>prev+item.goods_count,0)
    },
    totalPrice(){
      return this.list.filter(item=>item.goods_state).reduce((prev,item)=>prev+item.goods_count*item.goods_price,0)
    },
    isCheckAll:{
      get(){
      return this.list.every(item=>item.goods_state)
      },
      set(value){
        this.$emit('checkAll',value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.my-footer {
  width: 100%;
  height: 100px;
  z-index: 999;
  background-color: #fff;
  // line-height: 50px;
  border-top: 1px solid #d7d7d7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  margin-top: 20px;
  .custom-control-label{
    margin-left: 10px;
  }
  .price {
    color: red;
    font-weight: bold;
    font-size: 15px;
  }
  .footer-btn {
    width: 135px;
    height: 50px;
    background-color: #005ea6;
    color: #fff;
    font-size: 22px;
    font-weight: 700;
    border-radius: 5px;
    text-align: center;
    line-height: 50px;
    cursor: pointer;
    border: none;
  }
  .footer-btn:hover{
    background-color: #d12;
  }
}
</style>
