<template>
	<div class="shop-cart">
		<mallHeader />
		<div class="mall-shop-cart container">
			<ul class="breadcrumb">
				<li>
					<a href="#" @click="$router.push('/ori-mall')"
						><i class="el-icon-s-home"></i
					></a>
				</li>
				<li><i class="el-icon-arrow-right"></i></li>
				<li><a href="#">购物车</a></li>
			</ul>
			<div class="empty-cart" v-if="list.length === 0 ">
				<img src="@/assets/images/empty-cart.png" alt="" />
				<ul>
					<li class="text">购物车空空的哦~，去看看心仪的商品吧~</li>
					<li class="goCart" @click="$router.push('/ori-mall')">
						去购物<i class="el-icon-arrow-right"></i>
					</li>
				</ul>
			</div>
			<div class="empty">
				<MyGoodsItem
					v-for="item in list"
					:key="item.id"
					:item="item"
					@change="changeFn"
					@changeCount="changeCountFn"
				></MyGoodsItem>
				<MyFooter :list="list" @checkAll="checkAllFn"></MyFooter>
			</div>
			<div class="product-items">
				<div class="fnPrev" @click="fnPrev()">
					<i class="el-icon-arrow-left"></i>
				</div>
				<div class="fnNext" @click="fnNext()">
					<i class="el-icon-arrow-right"></i>
				</div>
				<div
					class="product-item2"
					v-for="(item, index) in progressList"
					:key="index"
				>
					<a href="#"
						><img src="@/assets/images/h1.jpg" alt=""/>
						<img src="@/assets/images/h2.jpg" alt="" class="active"
					/></a>
					<div class="star">
						<ul>
							<li>
								<img src="@/assets/images/icon/星星.svg" alt="" />
							</li>
							<li>
								<img src="@/assets/images/icon/星星.svg" alt="" />
							</li>
							<li>
								<img src="@/assets/images/icon/星星.svg" alt="" />
							</li>
							<li>
								<img src="@/assets/images/icon/星星.svg" alt="" />
							</li>
							<li>
								<img src="@/assets/images/icon/灰星星.svg" alt="" />
							</li>
						</ul>
					</div>
					<h4>
						<a href="product.html" title="Pastrami bacon" target="_self">{{
							item.type
						}}</a>
					</h4>
					<div class="money">
						<span class="now-money">$55.00 </span>
						<span class="old-money">$76.00 </span>
					</div>
					<span class="lable">-9%</span>
					<!-- <span class="eye"
												><img src="@/assets/images/icon/眼睛.svg" alt=""
											/></span> -->
					<span class="refresh el-icon-refresh"></span>
					<span class="love el-icon-star-off"></span>
					<span class="addToCart">添加购物车</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import mallHeader from './header.vue'
import MyFooter from './components/MyFooter'
import MyGoodsItem from './components/MyGoodsItem'
export default {
	name: 'product',
	components: {
		mallHeader,
		MyFooter,
		MyGoodsItem,
	},
	data() {
		return {
			progressList: [
				{ type: 1 },
				{ type: 2 },
				{ type: 3 },
				{ type: 4 },
				{ type: 5 },
				{ type: 6 },
			],
			list: [
				{
					goods_count: 1,
					goods_img: 'https://www.escook.cn/vuebase/pics/1.png',
					goods_name:
						'班俏BANQIAO超火ins潮卫衣女士2020秋季新款韩版宽松慵懒风薄款外套带帽上衣',
					goods_price: 108,
					goods_state: true,
					id: 1,
				},
				{
					goods_count: 1,
					goods_img: 'https://www.escook.cn/vuebase/pics/1.png',
					goods_name:
						'班俏BANQIAO超火ins潮卫衣女士2020秋季新款韩版宽松慵懒风薄款外套带帽上衣',
					goods_price: 108,
					goods_state: true,
					id: 2,
				},
				{
					goods_count: 1,
					goods_img: 'https://www.escook.cn/vuebase/pics/1.png',
					goods_name:
						'班俏BANQIAO超火ins潮卫衣女士2020秋季新款韩版宽松慵懒风薄款外套带帽上衣',
					goods_price: 108,
					goods_state: true,
					id: 3,
				},
				{
					goods_count: 1,
					goods_img: 'https://www.escook.cn/vuebase/pics/1.png',
					goods_name:
						'班俏BANQIAO超火ins潮卫衣女士2020秋季新款韩版宽松慵懒风薄款外套带帽上衣',
					goods_price: 108,
					goods_state: true,
					id: 4,
				},
				{
					goods_count: 1,
					goods_img: 'https://www.escook.cn/vuebase/pics/1.png',
					goods_name:
						'班俏BANQIAO超火ins潮卫衣女士2020秋季新款韩版宽松慵懒风薄款外套带帽上衣',
					goods_price: 108,
					goods_state: true,
					id: 5,
				},
			],
		}
	},
	methods: {
		fnPrev() {
			let prev = [...this.progressList]
			prev.unshift(prev.pop())
			this.progressList = prev
			// console.log(this.progressList)
		},
		fnNext() {
			let next = [...this.progressList]
			next.push(next.shift())
			this.progressList = next
			// console.log(this.progressList)
		},
		changeFn(id) {
			const goods = this.list.find((item) => item.id === id)
			goods.goods_state = !goods.goods_state
		},
		checkAllFn(value) {
			this.list.filter((item) => (item.goods_state = value))
		},
		changeCountFn(id, count) {
			const goods = this.list.find((item) => item.id === id)
			goods.goods_count = count
		},
	},
}
</script>

<style lang="scss" scoped>
.shop-cart {
	color: #666;
	background-color: #f0f3ef;
  font-family: fangsong;
	li {
		list-style: none;
	}
	.container {
		width: 1200px;
		margin: 0 auto;
	}
	.mall-shop-cart {
		.breadcrumb {
			margin: 23px 0;
			background-color: transparent;
			padding: 0;
			display: inline-block;
			width: 100%;
			line-height: 100%;
			li {
				display: inline-block;
				padding-left: 8px;
				margin-right: 7px;
				position: relative;
				a {
					display: block;
					padding: 0;
					color: #999;
					text-decoration: none;
					font-size: 14px;
				}
				a:hover {
					color: #005ea6;
				}
				i {
					font-size: 14px;
				}
			}
		}
		.empty-cart {
			height: 500px;
			display: flex;
			width: 100%;
			justify-content: center;
			align-items: center;
			background-color: #fff;
			img {
				width: 200px;
				height: 200px;
			}
			ul {
				margin-top: 130px;
				.goCart {
					color: #005ea6;
					cursor: pointer;
				}
			}
		}
		.product-items {
			display: flex;
			// margin-bottom: 20px;
			margin-top: 20px;
			position: relative;
			background-color: #fff;
			overflow: hidden;
			.product-item2:hover .active {
				opacity: 0;
				transition: all 0.5s;
			}
			.product-item2:hover .eye {
				opacity: 1;
				animation: change2 0.5s;
			}
			.product-item2:hover .love {
				opacity: 1;
				animation: animLoadedHeader2 0.5s;
			}
			.product-item2:hover .refresh {
				opacity: 1;
				animation: animLoadedHeader1 0.5s;
			}
			.product-item2:hover .addToCart {
				opacity: 1;
				animation: animLoadedHeader3 0.5s;
			}
			.product-item2:hover .star {
				opacity: 0;
			}
			.product-item2:hover h4 {
				opacity: 0;
			}
			.product-item2 {
				position: relative;
				width: 240px;
				text-align: center;
				a {
					img {
						position: relative;
						width: 240px;
						height: 240px;
					}
					.active {
						// opacity: 0;
						position: absolute;
						top: 0;
						right: 0;
					}
				}
				.star {
					ul {
						display: flex;
						padding-left: 90px;
						margin-bottom: 5px;
						li {
							list-style: none;
							img {
								width: 12px;
								height: 12px;
							}
						}
					}
				}
				h4 {
					font-size: 13px;
					color: #333;
				}
				.money {
					margin-bottom: 10px;
					margin-top: 10px;
					.now-money {
						color: #005ea6;
						font-size: 16px;
						font-weight: 600;
					}
					.old-money {
						text-decoration: line-through;
						color: #aaa;
					}
				}
				.top-right {
					flex: 1;
					// background-color: #005ea6;
					display: flex;
					// justify-content: center;
					margin-left: 88px;
					align-items: center;
					.dot1 {
						width: 30px;
						height: 8px;
						border-radius: 4px;
						background-color: #005ea6;
						margin-right: 5px;
					}
					.dot2 {
						width: 8px;
						height: 8px;
						margin: 0 5px;
						background-color: #222;
						border-radius: 8px;
					}
				}
				.lable {
					width: 38px;
					height: 38px;
					border-radius: 38px;
					background-color: #ffd839;
					text-align: center;
					line-height: 38px;
					position: absolute;
					top: 0;
					right: 0;
				}
				.eye {
					width: 30px;
					height: 30px;
					background-color: #005ea6;
					position: absolute;
					top: 115px;
					right: 115px;
					text-align: center;
					line-height: 30px;
					border-radius: 30px;
					transform-origin: center;
					opacity: 0;
				}
				.eye:hover {
					background-color: #ff5e00;
				}
				.refresh {
					width: 30px;
					height: 30px;
					position: absolute;
					border: 2px solid #005ea6;
					top: 255px;
					right: 25px;
					text-align: center;
					border-radius: 30px;
					line-height: 25px;
					color: #005ea6;
					opacity: 0;
				}
				.refresh:hover {
					background-color: #005ea6;
					color: #fff;
				}
				.love {
					width: 30px;
					height: 30px;
					position: absolute;
					border: 2px solid #005ea6;
					top: 255px;
					right: 60px;
					text-align: center;
					line-height: 25px;
					border-radius: 30px;
					color: #005ea6;
					opacity: 0;
				}
				.love:hover {
					background-color: #005ea6;
					color: #fff;
				}
				.addToCart {
					height: 30px;
					width: 100px;
					position: absolute;
					top: 255px;
					right: 105px;
					text-align: center;
					background-color: #005ea6;
					color: #fff;
					line-height: 30px;
					border-radius: 15px;
					opacity: 0;
				}
				.addToCart:hover {
					background-color: #ff5e00;
				}
			}
			.fnPrev {
				width: 44px;
				height: 44px;
				border-radius: 44px;
				text-align: center;
				line-height: 44px;
				z-index: 999;
				position: absolute;
				top: 155px;
				left: 0;
				box-shadow: 2px 0px 3px 0px rgb(155 155 155 / 75%);
				background-color: #fff;
				opacity: 0;
			}
			.fnPrev:hover {
				background-color: #005ea6;
				color: #fff;
			}
			.fnNext {
				width: 44px;
				height: 44px;
				border-radius: 44px;
				text-align: center;
				line-height: 44px;
				z-index: 999;
				position: absolute;
				top: 155px;
				right: 0;
				box-shadow: 2px 0px 3px 0px rgb(155 155 155 / 75%);
				background-color: #fff;
				opacity: 0;
			}
			.fnNext:hover {
				background-color: #005ea6;
				color: #fff;
			}
		}
		.product-items:hover .fnNext {
			opacity: 1;
			animation: change2 0.5s;
		}
		.product-items:hover .fnPrev {
			opacity: 1;
			animation: change2 0.5s;
		}
	}
	@keyframes change2 {
		from {
			transform: scale(0.5);
			opacity: 0;
		}
		to {
			transform: scale(1);
			opacity: 1;
		}
	}
	@keyframes animLoadedHeader1 {
		to {
			opacity: 1;
			transform: translate3d(0, 0px, 0);
		}
		from {
			opacity: 0;
			transform: translate3d(0, -40px, 0);
		}
	}
	@keyframes animLoadedHeader2 {
		to {
			opacity: 1;
			transform: translate3d(0, 0px, 0);
		}
		from {
			opacity: 0;
			transform: translate3d(0, -45px, 0);
		}
	}
	@keyframes animLoadedHeader3 {
		to {
			opacity: 1;
			transform: translate3d(0, 0px, 0);
		}
		from {
			opacity: 0;
			transform: translate3d(0, -50px, 0);
		}
	}
}
</style>
