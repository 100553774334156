<template>
  <div class="my-goods-item">
    <div class="left">
      <div class="custom-control custom-checkbox">
        <input
          type="checkbox"
          class="custom-control-input"
          :id="item.id"
          :checked="item.goods_state"
          @change="change(item.id)"
        />
        <label class="custom-control-label" :for="item.id">
          <img :src="item.goods_img" alt="" />
        </label>
      </div>
    </div>
    <div class="right">
      <div class="top">{{ item.goods_name }}</div>
      <div class="bottom">
        <span class="price">¥ {{ item.goods_price }}</span>
        <MyCount
          @changeCount="changeCountFn"
          :count="item.goods_count"
        ></MyCount>
      </div>
    </div>
  </div>
</template>

<script>
import MyCount from './MyCount'
export default {
  components: {
    MyCount,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    change(id) {
      this.$emit('change', id)
    },
    changeCountFn(count) {
      this.$emit('changeCount', this.item.id, count)
    },
  },
}
</script>

<style lang="scss" scoped>
.my-goods-item {
  display: flex;
  border-bottom: 1px solid #ccc;
  background-color: #fff;
  padding: 20px 20px;
  margin-bottom: 20px;
  .left {
    img {
      width: 120px;
      height: 120px;
      margin-right: 8px;
      border-radius: 10px;
      margin-left: 10px;
    }
    .custom-control-label::before,
    .custom-control-label::after {
      top: 50px;
    }
  }
  .right {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .bottom {
      display: flex;
      justify-content: space-between;
      padding: 5px 0;
      .price {
        color: red;
        font-weight: bold;
      }
    }
  }
}
</style>
